.index-send_sms_modal_container__216z5 {
  min-width: 300px;
}
.index-send_sms_modal_container__216z5 .index-send_sms_modal_container_header__2WHOT {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 16px;
}
.index-send_sms_modal_container__216z5 .index-send_sms_modal_container_header__header__Ib2lt {
  font-size: 20px;
  line-height: 26px;
  color: #3b464e;
  font-weight: 700;
}
.index-send_sms_modal_container__216z5 .index-send_sms_modal_container_header__close__2EWRJ {
  cursor: pointer;
}
.index-send_sms_modal_container__216z5 .index-send_sms_modal_container_header_form_group__2bGUa:not(.index-send_sms_modal_container__216z5 .index-send_sms_modal_container_header_form_group__2bGUa:last-child) {
  margin-bottom: 16px;
}
.index-send_sms_modal_container__216z5 .index-send_sms_modal_container_header_form_group__2bGUa label {
  color: #637280;
  margin-bottom: 8px;
  display: block;
}
.index-send_sms_modal_container__216z5 .index-send_sms_modal_container_header_form_group__2bGUa textarea {
  min-height: 70px;
}
.index-send_sms_modal_container__btns__1oB3T {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 16px;
}
.index-send_sms_modal_container__btns--submit__3Z7fl {
  font-size: 16px;
  line-height: 20px;
  width: 100px;
  height: 40px;
}
.index-send_sms_modal_container__216z5 .index-send_sms_modal_container_form--sending__B_DTm {
  opacity: 0.5;
  pointer-events: none;
}
