.dropdown-profile-container__2uSWW .dropdown-profile-profile_btn__1ulke {
  padding: 7px 8px 4px;
  border: solid 1px #c0c7ce;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}
.dropdown-profile-container__2uSWW .dropdown-profile-profile_btn__1ulke:hover {
  border: solid 1px #c0c7ce !important;
}
.dropdown-profile-container__2uSWW .dropdown-profile-profile_btn__1ulke .dropdown-profile-icon_profile__317wO {
  display: inline-block;
  border-radius: 3px;
}
.dropdown-profile-container__2uSWW .dropdown-profile-dropdown_content__2cbR2 {
  position: absolute;
  right: -33px;
  top: calc(100% + 40px);
  min-width: 150px;
}
.dropdown-profile-container__2uSWW .dropdown-profile-dropdown_content__2cbR2 .dropdown-profile-dropdown_panel__1_57L {
  padding: 15px 0;
}
.dropdown-profile-container__2uSWW .dropdown-profile-menu_item__2L5Rp {
  margin-bottom: 8px;
  white-space: nowrap;
  padding: 0 15px;
}
.dropdown-profile-container__2uSWW .dropdown-profile-menu_item__2L5Rp.dropdown-profile-divider__24CLF {
  border-bottom: solid 1px #c0c7ce;
  padding-bottom: 15px;
}
.dropdown-profile-container__2uSWW .dropdown-profile-icon_logout__mJCf9 {
  display: inline-block;
  margin-right: 5px;
}
.dropdown-profile-container__2uSWW .dropdown-profile-icon_logout__mJCf9 svg {
  width: 12px;
  height: 13px;
  fill: #9ca9b6;
}
.dropdown-profile-container__2uSWW a {
  text-decoration: none;
  color: #2a86e1;
  cursor: pointer;
  font-size: 15px;
}
