body {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
body.app-no_scroll__n3yPF {
  overflow-y: hidden;
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
p {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@include fontFamilyBold;
i {
  font-style: normal;
}
button:focus,
button:hover,
button:visited,
a:focus,
a:hover,
a:visited {
  outline: none;
}
.app-main_container__2Jj5b .app-background__3DJNm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #637280;
  opacity: 0.5;
  z-index: 4;
}
.app-main_container__2Jj5b .app-main_content__2hRPF {
  min-width: 1700px;
  padding: 0 30px;
  position: relative;
  margin-bottom: 40px;
}
.app-main_container__2Jj5b .app-header__2TxX8 {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  min-height: 44px;
  background-color: #fff;
  z-index: 4;
  min-width: 1700px;
  padding-bottom: 10px;
}
.app-main_container__2Jj5b .app-header__2TxX8::after {
  content: '';
  display: block;
  border-bottom: 2px solid #f7f9fa;
}
.app-main_container__2Jj5b .app-header__2TxX8 .app-main_content__2hRPF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0;
}
.app-main_container__2Jj5b .app-header__2TxX8 .app-search_wrap__32k2w {
  position: relative;
  margin-left: 20px;
}
.app-main_container__2Jj5b .app-header__2TxX8 .app-search_wrap__32k2w span {
  position: relative;
  margin-right: 20px;
  display: inline-block;
}
.app-main_container__2Jj5b .app-header__2TxX8 .app-search_wrap__32k2w .app-icon_search__1SUA1 {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
}
.app-main_container__2Jj5b .app-header__2TxX8 .app-search_wrap__32k2w input {
  width: 170px;
}
.app-main_container__2Jj5b .app-header__2TxX8 .app-header_right__1I-bY {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.app-main_container__2Jj5b .app-header__2TxX8 .app-header_left__2o-VE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.app-main_container__2Jj5b .app-header__2TxX8 .app-project_btn__2aHIH {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E %3Cg fill='%23389BFE' fill-rule='evenodd'%3E %3Cpath d='M2.32.435a2.32 2.32 0 1 0 0 4.64 2.32 2.32 0 0 0 0-4.64M9.754.435a2.32 2.32 0 1 0 0 4.64 2.32 2.32 0 0 0 0-4.64M17.188.435a2.32 2.32 0 1 0 0 4.64 2.32 2.32 0 0 0 0-4.64M2.32 7.868a2.32 2.32 0 1 0 0 4.64 2.32 2.32 0 0 0 0-4.64M9.754 7.868a2.32 2.32 0 1 0 0 4.64 2.32 2.32 0 0 0 0-4.64M17.188 7.868a2.32 2.32 0 1 0 0 4.64 2.32 2.32 0 0 0 0-4.64M2.32 15.302a2.32 2.32 0 1 0 0 4.64 2.32 2.32 0 0 0 0-4.64M9.754 15.302a2.32 2.32 0 1 0 0 4.64 2.32 2.32 0 0 0 0-4.64M17.188 15.302a2.32 2.32 0 1 0 0 4.64 2.32 2.32 0 0 0 0-4.64'/%3E %3C/g%3E %3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ebf7ff;
}
.app-main_container__2Jj5b .app-tooltip__2q9KA {
  position: relative;
  cursor: pointer;
}
.app-main_container__2Jj5b .app-tooltip__2q9KA .app-tooltip_inner__jNtYj {
  position: absolute;
  border-radius: 1px;
  background-color: #627281;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.13);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.13);
  font-size: 12px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #fff;
  padding: 2px 6px;
  white-space: nowrap;
  bottom: calc(100% + 3px);
  left: 35%;
  height: 0;
  width: 0;
  opacity: 0;
}
.app-main_container__2Jj5b .app-tooltip__2q9KA:hover .app-tooltip_inner__jNtYj {
  height: auto;
  width: auto;
  opacity: 1;
}
.app-main_container__2Jj5b .app-new__oqoKZ {
  display: inline-block;
  min-height: 12px;
  min-width: 12px;
  background-color: #fc694f;
  border-radius: 3px;
}
.app-main_container__2Jj5b .app-tag__rUiv4 {
  display: inline-block;
  height: 30px;
  border-radius: 100px;
  border: solid 1px #389bfe;
  color: #2a86e1;
  margin-right: 10px;
  padding: 0 13px;
  font-size: 14px;
  line-height: 27px;
}
::-webkit-scrollbar-track {
  border-radius: 4px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #f0f2f5;
}
.app-error__3LVNE {
  text-align: center;
  color: #fc694f;
  margin: 40px 0 0;
  font-size: 18px;
}
