.bonus-campaigns-page-btn_wrap__hSgDZ {
  margin-right: 10px;
}
.bonus-campaigns-page-selected__35LG1 {
  font-size: 13px;
  color: #9ca9b6;
}
.bonus-campaigns-page-sub_category_tooltip__2I6_k {
  word-break: break-word !important;
  white-space: pre !important;
  text-align: left;
}
