.communications-btn_wrap__3vtxi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 20px;
}
.communications-btn_wrap__3vtxi button {
  margin-left: 10px;
}
