.financeNav-nav__3E7mZ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.financeNav-nav__3E7mZ a {
  display: inline-block;
  font-size: 14px;
  padding: 15px;
  position: relative;
}
.financeNav-nav__3E7mZ a.financeNav-active__1GMuJ {
  background-color: #f7f9fa;
}
.financeNav-nav__3E7mZ .financeNav-new__1PAMS {
  position: absolute;
  display: inline-block;
  padding: 0 4px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  color: #fff;
  background-color: #fc694f;
  font-size: 8px;
  border-radius: 3px;
  right: 5px;
  top: 3px;
  font-style: normal;
}
