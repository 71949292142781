.shiftsStyles-link_search_container__F8__A {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.shiftsStyles-link_search_container__F8__A .shiftsStyles-search_wrap__2Cob9 {
  margin-left: 20px;
  position: relative;
}
.shiftsStyles-link_search_container__F8__A .shiftsStyles-search_wrap__2Cob9 i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 7px;
}
.shiftsStyles-link_search_container__F8__A .shiftsStyles-search_wrap__2Cob9 input {
  padding-right: 24px;
}
