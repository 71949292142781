.index-assign_modal_container__2mv02 {
  width: 340px;
  min-height: 300px;
}
.index-assign_modal_container__2mv02 .index-assign_modal_container_header__1qcuE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 4px;
}
.index-assign_modal_container__2mv02 .index-assign_modal_container_header__header__21LFU {
  font-size: 20px;
  line-height: 26px;
  color: #3b464e;
  font-weight: 700;
}
.index-assign_modal_container__2mv02 .index-assign_modal_container_header__close__3LJRu {
  cursor: pointer;
}
.index-assign_modal_container__title__N3cU2 {
  font-size: 13px;
  line-height: 18px;
  color: #9ca9b6;
  margin-bottom: 24px;
}
.index-assign_modal_container__success_text__ONvpw {
  max-width: 340px;
  text-align: center;
}
.index-assign_modal_container__loader__vdo8I {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 180px;
}
.index-assign_modal_container__loader--error__3dJuN {
  color: #fc694f;
}
.index-assign_modal_container__2mv02 .index-assign_modal_container_check_list__2Qfbj {
  margin-bottom: 20px;
}
.index-assign_modal_container__2mv02 .index-assign_modal_container_check_list__operator__3Cq_o span {
  font-size: 16px;
  line-height: 20px;
  color: #3b464e;
  margin-left: 10px;
}
.index-assign_modal_container__2mv02 .index-assign_modal_container_check_list__operator__3Cq_o i {
  width: 16px;
  height: 16px;
}
.index-assign_modal_container__btns__3PZ4T {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.index-assign_modal_container__btns--submit__3lmmn {
  font-size: 16px;
  line-height: 20px;
  width: 100px;
  height: 40px;
}
