.index-container__1jagm {
  margin-right: 10px;
}
.index-container__1jagm .index-settings_btn__1KbvE {
  padding: 3px !important;
  border: solid 1px #c0c7ce;
  border-radius: 3px;
  -webkit-transition: none !important;
  transition: none !important;
}
.index-container__1jagm .index-settings_btn__1KbvE:hover {
  border: solid 1px #3099ff !important;
}
.index-container__1jagm .index-settings_btn__1KbvE:hover svg {
  fill: #3099ff !important;
}
