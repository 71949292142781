.index-sms_sender_page__btn_block__1lF6v {
  margin-bottom: 20px;
}
.index-sms_sender_page__btn_block__1lF6v button {
  min-width: 106px;
}
.index-sms_sender_page__show_more__12ayD {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #3099ff;
  margin-top: 45px;
  font-size: 16px;
  cursor: pointer;
}
.index-sms_sender_page__show_more__12ayD:hover {
  text-decoration: underline;
}
.index-sms_sender_page_loader_wrapper__2F062 {
  position: absolute;
  top: 50%;
  width: 100vw;
  height: 100vh;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
