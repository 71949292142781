.grid-row__2ll3f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
}
.grid-row__2ll3f.grid-vertical__21uvl {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.grid-row__2ll3f.grid-space_between__tRu6I {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.grid-row__2ll3f.grid-start__yFLVK {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.grid-row__2ll3f.grid-end__2s9WX {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.grid-row__2ll3f.grid-column_gap_4__3qYxk {
  gap: 0 4px;
}
.grid-row__2ll3f.grid-column_gap_8__fKzgB {
  gap: 0 8px;
}
.grid-row__2ll3f.grid-column_gap_10__14y9k {
  gap: 0 10px;
}
.grid-row__2ll3f.grid-column_gap_12__3gyYP {
  gap: 0 12px;
}
.grid-row__2ll3f.grid-column_gap_24__kTwOy {
  gap: 0 24px;
}
.grid-row__2ll3f.grid-column_gap_32__kUN9H {
  gap: 0 32px;
}
.grid-row__2ll3f.grid-column_gap_240__TBZY- {
  gap: 0 240px;
}
.grid-row__2ll3f.grid-row_gap_4__1OYCQ {
  gap: 4px 0;
}
.grid-row__2ll3f.grid-row_gap_8__13R8A {
  gap: 8px 0;
}
.grid-row__2ll3f.grid-row_gap_10__6Z5Zs {
  gap: 10px 0;
}
.grid-row__2ll3f.grid-row_gap_12__3E63s {
  gap: 12px 0;
}
.grid-row__2ll3f.grid-row_gap_24__2LCag {
  gap: 24px 0;
}
.grid-row__2ll3f.grid-row_gap_32__22UxS {
  gap: 32px 0;
}
.grid-row__2ll3f.grid-row_column_gap_4__N_Vvl {
  gap: 4px;
}
.grid-row__2ll3f.grid-row_column_gap_8__3CTE4 {
  gap: 8px;
}
.grid-row__2ll3f.grid-row_column_gap_10__1SNqA {
  gap: 10px;
}
.grid-row__2ll3f.grid-row_column_gap_12__3rkss {
  gap: 12px;
}
.grid-row__2ll3f.grid-row_column_gap_4_8__3-N2Z {
  gap: 4px 8px;
}
.grid-row__2ll3f > [class*="col_"] {
  padding: 0 10px;
}
.grid-row__2ll3f > [class*="col_"]:first-child {
  padding-left: 0;
}
.grid-row__2ll3f > [class*="col_"]:last-child {
  padding-right: 0;
}
.grid-row__2ll3f .grid-col_1__3QNTb {
  -ms-flex-preferred-size: 8.333333333333334%;
      flex-basis: 8.333333333333334%;
}
.grid-row__2ll3f .grid-col_2__Fqs-X {
  -ms-flex-preferred-size: 16.666666666666668%;
      flex-basis: 16.666666666666668%;
}
.grid-row__2ll3f .grid-col_3__30F9T {
  -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
}
.grid-row__2ll3f .grid-col_4__3mLPB {
  -ms-flex-preferred-size: 33.333333333333336%;
      flex-basis: 33.333333333333336%;
}
.grid-row__2ll3f .grid-col_5__3uULS {
  -ms-flex-preferred-size: 41.66666666666667%;
      flex-basis: 41.66666666666667%;
}
.grid-row__2ll3f .grid-col_6__1ZRpm {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
}
.grid-row__2ll3f .grid-col_7__2BSWK {
  -ms-flex-preferred-size: 58.333333333333336%;
      flex-basis: 58.333333333333336%;
}
.grid-row__2ll3f .grid-col_8__XuvKY {
  -ms-flex-preferred-size: 66.66666666666667%;
      flex-basis: 66.66666666666667%;
}
.grid-row__2ll3f .grid-col_9__2Mb2N {
  -ms-flex-preferred-size: 75%;
      flex-basis: 75%;
}
.grid-row__2ll3f .grid-col_10__2PqvR {
  -ms-flex-preferred-size: 83.33333333333334%;
      flex-basis: 83.33333333333334%;
}
.grid-row__2ll3f .grid-col_11__3X2RQ {
  -ms-flex-preferred-size: 91.66666666666667%;
      flex-basis: 91.66666666666667%;
}
.grid-row__2ll3f .grid-col_12__1n-N3 {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
