.finances-amounts__3Il8Q {
  line-height: 20px;
  color: #3b464e;
  margin-right: 20px;
}
.finances-important__3CudL {
  color: #fc694f;
  font-weight: bold;
}
.finances-statistic__3D3rA {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: 0.1px;
  color: #3b464e;
  font-size: 14px;
}
.finances-statistic__3D3rA li {
  margin-right: 15px;
}
.finances-statistic__3D3rA .finances-statistic_title__28I_x {
  display: inline-block;
  margin-right: 5px;
}
.finances-btn_approve__3oH0X {
  margin-right: 10px;
}
.finances-header_wrapper__2mdJ_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 45px;
}
.finances-header_wrapper__2mdJ_.finances-header_wrapper_right__23Bb- {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.finances-filter__3uLXe {
  padding: 0 0 10px;
  position: sticky;
  position: -webkit-sticky;
  top: 58px;
  background-color: #fff;
  z-index: 3;
}
.finances-nested_list__1ynIv {
  border-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
  border: solid 1px #c0c8ce;
  padding: 15px;
  cursor: default;
  position: absolute;
  top: 0;
  left: calc(100% + 6px);
}
.finances-multi_select__18UBj {
  overflow: visible;
}
.finances-arrow_container__2ZSAD {
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}
.finances-select_parent_item__1qGL3:after {
  display: none !important;
}
