.index-settings_log__33Q75 {
  margin-top: 20px;
  pointer-events: all !important;
}
.index-settings_log__33Q75 .index-settings_log_show_more__3x3N8 {
  text-align: center;
  font-size: 16px;
  padding: 16px 0 16px 0;
  cursor: pointer;
  color: #389bfe;
  text-decoration: none;
}
.index-settings_log__33Q75 .index-settings_log_show_more__3x3N8:hover {
  text-decoration: underline;
}
.index-settings_log__33Q75 .index-settings_log_log_body__rEuPD {
  position: relative;
}
.index-settings_log__33Q75 .index-settings_log_loader__7DUMY {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 16px;
}
