.orders-page-filter__1-kRg {
  min-width: calc(1700px - 30px);
  padding: 0 0 10px;
  position: sticky;
  position: -webkit-sticky;
  top: 58px;
  background-color: #fff;
  z-index: 3;
}
.orders-page-filter__1-kRg > div {
  margin-right: 15px;
}
