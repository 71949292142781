.icon-svg_icon__3rGGg {
  display: inline-block;
  -webkit-transition: fill 0.5s;
  transition: fill 0.5s;
  width: 20px;
  height: 20px;
}
.icon-triangle__2SdnG {
  width: 6px;
  height: 11px;
  fill: #3099ff;
}
.icon-message__1wd_T {
  width: 16px;
  height: 13px;
  fill: #fc694f;
}
.icon-tag__2AcxM {
  width: 17px;
  height: 17px;
  fill: #389bfe;
}
.icon-comment__2OOgU {
  width: 15px;
  height: 14px;
  fill: #389bfe;
}
.icon-disabled__1DXkI {
  fill: #627281 !important;
}
.icon-search__3sqpb {
  width: 14px;
  height: 15px;
  fill: #a7a8ac;
}
.icon-user__1J13X {
  fill: #c0c7ce;
  width: 15px;
  height: 15px;
}
.icon-sun__3briH {
  width: 14px;
  height: 15px;
  fill: #ffa65d;
  top: 2px;
  position: relative;
  margin-left: 6px;
}
.icon-moon__12eeE {
  width: 13px;
  height: 12px;
  fill: #c0c7ce;
  top: 2px;
  position: relative;
  margin-left: 6px;
}
.icon-comment-notice__3LIRi {
  width: 11px;
  height: 9px;
  margin-left: 6px;
}
.icon-icon-ticket__11HtI {
  fill: #627281;
  width: 17px;
  height: 17px;
}
div > .icon-icon-ticket__11HtI {
  fill: #389bfe;
}
.icon-icon-ticket-field__2VDsQ {
  fill: #c0c7ce;
  width: 17px;
  height: 17px;
}
.icon-display-settings__g7-rg {
  width: 24px;
  height: 24px;
  fill: #ced4da;
}
