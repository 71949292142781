.index-defense_controls_page__defense_system__3gl6g {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0 20px;
  margin-bottom: 20px;
}
.index-defense_controls_page__defense_system__3gl6g > div {
  min-width: 135px;
}
.index-defense_controls_page__value__31GF0 {
  text-transform: capitalize;
}
.index-defense_controls_page__DPHn6 button {
  min-width: 106px;
}
.index-defense_controls_page_loader_wrapper__1-H8Q {
  position: absolute;
  top: 50%;
  width: 100vw;
  height: 100vh;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
