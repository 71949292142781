.sandbox-page-filter__2It60 {
  position: sticky;
  position: -webkit-sticky;
  padding: 0 0 10px;
  top: 58px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sandbox-page-confirmed__yQGte {
  color: #00af6f;
}
.sandbox-page-overflow_message__1kBiZ {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;
  overflow: hidden;
}
.sandbox-page-checkboxContainer__35K6o {
  display: inline-block;
}
.sandbox-page-btn_container__1Iwlg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-right: 8px;
  margin-bottom: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sandbox-page-btn_container__no_btn__37ikg {
  font-size: 14px;
  color: #637280;
}
.sandbox-page-btn_container__error_upload__3WdLx {
  line-height: 20px;
  color: #fc694f;
  margin-right: 24px;
  font-weight: 700;
}
.sandbox-page-file_input__3r5AE {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  left: -9999px;
}
