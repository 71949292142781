.mainNav-row__PGGdl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
}
.mainNav-row__PGGdl.mainNav-vertical__1sotP {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mainNav-row__PGGdl.mainNav-space_between__1vGOo {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.mainNav-row__PGGdl.mainNav-start__3w9k7 {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.mainNav-row__PGGdl.mainNav-end__2WJky {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.mainNav-row__PGGdl.mainNav-column_gap_4__2zw13 {
  gap: 0 4px;
}
.mainNav-row__PGGdl.mainNav-column_gap_8__1iJ0q {
  gap: 0 8px;
}
.mainNav-row__PGGdl.mainNav-column_gap_10__1tLr8 {
  gap: 0 10px;
}
.mainNav-row__PGGdl.mainNav-column_gap_12__3XqRX {
  gap: 0 12px;
}
.mainNav-row__PGGdl.mainNav-column_gap_24__3FTOU {
  gap: 0 24px;
}
.mainNav-row__PGGdl.mainNav-column_gap_32__38c2H {
  gap: 0 32px;
}
.mainNav-row__PGGdl.mainNav-column_gap_240__2w93Q {
  gap: 0 240px;
}
.mainNav-row__PGGdl.mainNav-row_gap_4__L5_WS {
  gap: 4px 0;
}
.mainNav-row__PGGdl.mainNav-row_gap_8__14Ayz {
  gap: 8px 0;
}
.mainNav-row__PGGdl.mainNav-row_gap_10__1mNsM {
  gap: 10px 0;
}
.mainNav-row__PGGdl.mainNav-row_gap_12__3HQgx {
  gap: 12px 0;
}
.mainNav-row__PGGdl.mainNav-row_gap_24__2x0BF {
  gap: 24px 0;
}
.mainNav-row__PGGdl.mainNav-row_gap_32__39W8P {
  gap: 32px 0;
}
.mainNav-row__PGGdl.mainNav-row_column_gap_4__pymXt {
  gap: 4px;
}
.mainNav-row__PGGdl.mainNav-row_column_gap_8__3NiZr {
  gap: 8px;
}
.mainNav-row__PGGdl.mainNav-row_column_gap_10__1mMbx {
  gap: 10px;
}
.mainNav-row__PGGdl.mainNav-row_column_gap_12__2y_Fq {
  gap: 12px;
}
.mainNav-row__PGGdl.mainNav-row_column_gap_4_8__2N6TG {
  gap: 4px 8px;
}
.mainNav-row__PGGdl > [class*="col_"] {
  padding: 0 10px;
}
.mainNav-row__PGGdl > [class*="col_"]:first-child {
  padding-left: 0;
}
.mainNav-row__PGGdl > [class*="col_"]:last-child {
  padding-right: 0;
}
.mainNav-row__PGGdl .mainNav-col_1__Yuqoa {
  -ms-flex-preferred-size: 8.333333333333334%;
      flex-basis: 8.333333333333334%;
}
.mainNav-row__PGGdl .mainNav-col_2__3j2wC {
  -ms-flex-preferred-size: 16.666666666666668%;
      flex-basis: 16.666666666666668%;
}
.mainNav-row__PGGdl .mainNav-col_3__2BjWf {
  -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
}
.mainNav-row__PGGdl .mainNav-col_4__3TuXb {
  -ms-flex-preferred-size: 33.333333333333336%;
      flex-basis: 33.333333333333336%;
}
.mainNav-row__PGGdl .mainNav-col_5__2_S3z {
  -ms-flex-preferred-size: 41.66666666666667%;
      flex-basis: 41.66666666666667%;
}
.mainNav-row__PGGdl .mainNav-col_6__3M86T {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
}
.mainNav-row__PGGdl .mainNav-col_7__3ia9T {
  -ms-flex-preferred-size: 58.333333333333336%;
      flex-basis: 58.333333333333336%;
}
.mainNav-row__PGGdl .mainNav-col_8__2eJXk {
  -ms-flex-preferred-size: 66.66666666666667%;
      flex-basis: 66.66666666666667%;
}
.mainNav-row__PGGdl .mainNav-col_9__3ADpo {
  -ms-flex-preferred-size: 75%;
      flex-basis: 75%;
}
.mainNav-row__PGGdl .mainNav-col_10__1QZHE {
  -ms-flex-preferred-size: 83.33333333333334%;
      flex-basis: 83.33333333333334%;
}
.mainNav-row__PGGdl .mainNav-col_11__3gMzu {
  -ms-flex-preferred-size: 91.66666666666667%;
      flex-basis: 91.66666666666667%;
}
.mainNav-row__PGGdl .mainNav-col_12__1wytu {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
.mainNav-main_nav__YhuGV .mainNav-item__14p11 {
  display: inline-block;
  position: relative;
}
.mainNav-main_nav__YhuGV .mainNav-item__14p11 a {
  display: block;
  padding: 13px 10px;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
  text-decoration: none;
  color: #3099ff;
  cursor: pointer;
  font-size: 14px;
}
.mainNav-main_nav__YhuGV .mainNav-item__14p11 a:hover {
  text-decoration: none;
  background-color: #f7f9fa;
}
.mainNav-main_nav__YhuGV .mainNav-item__14p11 a.mainNav-active__1q7yD {
  background-color: #f7f9fa;
}
.mainNav-main_nav__YhuGV .mainNav-item__14p11 a .mainNav-new__29Ow4 {
  position: absolute;
  display: inline-block;
  padding: 0 2px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  color: #fff;
  background-color: #fc694f;
  font-size: 8px;
  border-radius: 3px;
  right: 5px;
  top: 3px;
  font-style: normal;
}
