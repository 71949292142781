.create-promo-button-container__1k5Sq {
  padding: 0 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: sticky;
  position: -webkit-sticky;
  background-color: #fff;
  z-index: 1;
  top: 58px;
}
.create-promo-button-container__1k5Sq button {
  width: 100px;
}
