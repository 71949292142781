.index-settings_tabs__3UwWT {
  width: calc(100% + 60px);
  background: #f7f9fa;
  height: 42px;
  margin-left: -30px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 30px;
  position: relative;
}
.index-settings_tabs__item__1xw3h {
  padding: 12px 24px 14px 24px;
  font-size: 14px;
  line-height: 16px;
  color: #637280;
  cursor: pointer;
}
.index-settings_tabs__3UwWT .index-active__2fmwo {
  color: #2284e4;
}
.index-settings_tabs__3UwWT .index-line__3o4On {
  position: absolute;
  height: 2px;
  background: #3099ff;
  -webkit-transition: left 0.25s;
  transition: left 0.25s;
  width: 143px;
  bottom: 0;
}
.index-settings_page__ludlT {
  padding: 0 24px 24px 24px;
  margin-top: -12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  min-height: 20vh;
}
.index-settings_page--read-only__CGt3W {
  opacity: 0.7;
  pointer-events: none;
}
.index-settings_page--can_be_editable__1J-gN {
  opacity: 1;
  pointer-events: all;
}
.index-settings_page__ludlT .index-settings_page_loader_wrapper__12YLW {
  position: absolute;
  top: 50%;
  left: 50%;
}
.index-settings_page__header__1YSlO {
  margin-bottom: -20px;
}
.index-settings_page__ludlT .index-settings_page_content_wrapper__22jpJ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.index-settings_page__ludlT .index-settings_page_content_wrapper__22jpJ button.index-settings_page_submit__2b_ec {
  width: 225px;
  height: 40px;
  position: relative;
  pointer-events: all;
  opacity: 1;
}
.index-settings_page__ludlT .index-settings_page_content_wrapper__22jpJ button.index-settings_page_submit__2b_ec .index-settings_page_update_notification__2FMSu {
  position: absolute;
  color: #3099ff;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: all 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  transition: all 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  top: 0;
  opacity: 0;
}
.index-settings_page__ludlT .index-settings_page_content_wrapper__22jpJ button.index-settings_page_submit__2b_ec .index-settings_page_update_notification__2FMSu.index-settings_page_update_notification_visible__1pfNX {
  top: -22px;
  opacity: 1;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O {
  padding: 0 37px 45px 37px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 0 50px;
}
.index-settings_page__ludlT .index-settings_page_main_content--block__1Njhr {
  display: block;
}
.index-settings_page__ludlT .index-settings_page_main_content--w-50__3vXbA {
  width: 50%;
}
.index-settings_page__ludlT .index-settings_page_main_content__header__2PIjD {
  font-size: 20px;
  margin-top: 62px;
  font-weight: 700;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__34TIC {
  padding: 22px 0 12px 0;
  border-bottom: 1px solid #e6e8eb;
  color: #3b464e;
  font-size: 16px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item--no-border__3I2qe {
  border: none;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item--with-margin-b-21__1Vhjc {
  margin-bottom: 21px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__34TIC .index-first_flex_item__3p3Hc {
  min-width: 600px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__header__3kPXJ {
  line-height: 19px;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 12px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__flex__3qQRn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__flex__mW_50__2BD0B {
  min-width: 50px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__flex__mW_126__3kMH4 {
  min-width: 126px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__flex_sbtw__2bfk8 {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__flex_lrP__2j9Tk {
  padding: 0 13px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__flex_gap_40__Qc6QT {
  gap: 0 40px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__flex_gap_12__3WI7z {
  gap: 0 12px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__flex_gap_8__-Wq24 {
  gap: 0 8px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__drop_btn__2XVr6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #3b464e;
  border-color: #c0c7ce;
  font-size: 16px;
  position: relative;
  min-width: 61px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__drop_btn__2XVr6:hover {
  background-color: transparent;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__drop_btn--big__1HK-g {
  min-width: 71px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__arrow__rrojA {
  display: block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA5IDYiPg0KICAgIDxwYXRoIGZpbGw9IiNBN0E4QUMiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTS43MTkuMDEyTDAgLjcwN2w0LjIxNSA0LjM2TDguNzA3LjcxOCA4LjAxMiAwIDQuMjM4IDMuNjUyeiIvPg0KPC9zdmc+DQo=");
  background-position: center;
  background-repeat: no-repeat;
  width: 8px;
  height: 8px;
  margin-left: 10px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__drop_container__38Sb6 {
  position: absolute;
  left: 0;
  top: calc(0px + 30px);
  width: 100%;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__34TIC .index-drop_top_7__1npsw {
  top: calc(0px - calc(7 * 27px) - 27px);
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__34TIC .index-drop_top_11__3JLI5 {
  top: calc(0px - calc(11 * 27px) - 21px);
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__drop_container_inner__1Z7Vn {
  width: 100%;
  text-align: left;
  max-height: 308px;
  overflow-y: auto;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__drop_container_inner_item__3OL0m {
  padding: 5px 10px;
  cursor: pointer;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__drop_container_inner_item_selected__FRkPC {
  background: #d6e9ff;
  border-radius: 3px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__drop_container_inner_item_disabled__Rtljh {
  color: #ccc;
  pointer-events: none;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__34TIC .index-settings_page_main_content_sub_item__HhCbC {
  padding: 12px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__34TIC .index-settings_page_main_content_sub_item--no-t-b-p__MRiBs {
  padding-top: 0;
  padding-bottom: 0;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__34TIC .index-settings_page_main_content_sub_item__HhCbC .index-first_flex_item__3p3Hc {
  min-width: calc(600px - 24px);
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__checkbox__3cDZi {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__checkbox__3cDZi:not(:last-child) {
  margin-bottom: 21px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__checkbox__3cDZi span {
  font-size: 16px !important;
  margin-left: 14px !important;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__input__1YBE6 {
  width: 58px;
  height: 30px;
  border: 1px solid #c0c7ce;
  border-radius: 3px;
  padding: 5px 10px;
  text-align: center;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__notification_block__3l_LX {
  margin-bottom: 12px;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__notification_block--base__3jj2O {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__notifications_icon__2nxBz {
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1625_7246)'%3E%3Cpath d='M11.5 6.5C11.5 9.53757 9.03757 12 6 12C2.96243 12 0.5 9.53757 0.5 6.5C0.5 3.46243 2.96243 1 6 1C9.03757 1 11.5 3.46243 11.5 6.5Z' fill='%23EBF7FF' stroke='%23389BFE'/%3E%3Cpath d='M6.53774 3.54565V6.11965C6.53774 6.39265 6.52424 6.66115 6.49724 6.92515C6.47024 7.18615 6.43424 7.46365 6.38924 7.75765H5.63774C5.59274 7.46365 5.55674 7.18615 5.52974 6.92515C5.50274 6.66115 5.48924 6.39265 5.48924 6.11965V3.54565H6.53774ZM5.31824 9.44065C5.31824 9.34765 5.33474 9.25915 5.36774 9.17515C5.40374 9.09115 5.45174 9.01915 5.51174 8.95915C5.57474 8.89915 5.64824 8.85115 5.73224 8.81515C5.81624 8.77915 5.90624 8.76115 6.00224 8.76115C6.09524 8.76115 6.18374 8.77915 6.26774 8.81515C6.35174 8.85115 6.42374 8.89915 6.48374 8.95915C6.54374 9.01915 6.59174 9.09115 6.62774 9.17515C6.66374 9.25915 6.68174 9.34765 6.68174 9.44065C6.68174 9.53665 6.66374 9.62665 6.62774 9.71065C6.59174 9.79165 6.54374 9.86215 6.48374 9.92215C6.42374 9.98215 6.35174 10.0287 6.26774 10.0617C6.18374 10.0977 6.09524 10.1157 6.00224 10.1157C5.90624 10.1157 5.81624 10.0977 5.73224 10.0617C5.64824 10.0287 5.57474 9.98215 5.51174 9.92215C5.45174 9.86215 5.40374 9.79165 5.36774 9.71065C5.33474 9.62665 5.31824 9.53665 5.31824 9.44065Z' fill='%23389BFE'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1625_7246'%3E%3Crect width='12' height='12' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.index-settings_page__ludlT .index-settings_page_main_content__38X-O .index-settings_page_main_content_item__notifications_value__3Dpjf {
  font-size: 14px;
  line-height: 17px;
}
.index-settings_page__ludlT .index-settings_page_main_content__two_column__3NXwW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #e6e8eb;
}
.index-settings_page__ludlT .index-settings_page_main_content__two_column__3NXwW .index-item_304__351cX {
  min-width: 304px;
}
.index-settings_page__ludlT .index-settings_page_main_content__two_column__3NXwW .index-settings_page_main_content_item__34TIC .index-first_flex_item__3p3Hc {
  min-width: 156px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 156px;
}
.index-settings_page__ludlT .index-settings_page_main_content__two_column__gap_40__3d8_K {
  gap: 0 40px;
}
.index-settings_page__ludlT .index-settings_page_payoneer_btn__1IzEz {
  color: #fc694f !important;
  background: none !important;
  border: none !important;
  min-width: 117px;
}
.index-settings_page__ludlT .index-settings_page_file_input__KqIou {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  left: -9999px;
}
.index-settings_page_info_success_text__2Dz_W {
  max-width: 400px;
}
