.create-client-dropdown-btn_add__21-Vr {
  display: inline-block;
  position: fixed;
  right: 13px;
  bottom: 120px;
  z-index: 1;
}
.create-client-dropdown-btn_add__21-Vr button {
  background-color: #3099ff;
  color: #fff;
  border-radius: 50%;
  font-size: 40px;
  width: 55px;
  height: 55px;
}
.create-client-dropdown-btn_add__21-Vr button:hover {
  background-color: #2a86e1;
  color: #fff;
}
