.bug_tracker-status__H_L27 {
  color: #42526e;
  background: #dfe1e6;
  text-transform: lowercase;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  display: inline-block;
}
.bug_tracker-priority__2UtcF {
  display: inline-block;
  height: 16px;
  width: 16px;
}
.bug_tracker-priority__2UtcF.bug_tracker-low__duF6b {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E %3Cpath d='M12.5 6.1c.5-.3 1.1-.1 1.4.4.3.5.1 1.1-.3 1.3l-5 3c-.3.2-.7.2-1 0l-5-3c-.6-.2-.7-.9-.4-1.3.2-.5.9-.7 1.3-.4L8 8.8l4.5-2.7z' fill='%230065ff'/%3E %3C/svg%3E");
}
.bug_tracker-priority__2UtcF.bug_tracker-lowest__3KW9k {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E %3Cpath d='M12.504883 8.14541c.5-.3 1.1-.1 1.4.4s.1 1-.4 1.3l-5 3c-.3.2-.7.2-1 0l-5-3c-.5-.3-.6-.9-.3-1.4.2-.4.8-.6 1.3-.3l4.5 2.7 4.5-2.7z' fill='%230065ff'/%3E %3Cpath d='M12.504883 3.84541c.5-.3 1.1-.2 1.4.3s.1 1.1-.4 1.4l-5 3c-.3.2-.7.2-1 0l-5-3c-.5-.3-.6-.9-.3-1.4.3-.5.9-.6 1.4-.3l4.4 2.7 4.5-2.7z' fill='%232684ff'/%3E %3C/svg%3E");
}
.bug_tracker-priority__2UtcF.bug_tracker-high__1Xkh9 {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E %3Cpath d='M3.5 9.9c-.5.3-1.1.1-1.4-.3s-.1-1.1.4-1.4l5-3c.3-.2.7-.2 1 0l5 3c.5.3.6.9.3 1.4-.3.5-.9.6-1.4.3L8 7.2 3.5 9.9z' fill='%23ff5630'/%3E %3C/svg%3E");
}
.bug_tracker-priority__2UtcF.bug_tracker-highest__rDC3Z {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E %3Cpath d='M3.47876 7.9c-.5.3-1.1.1-1.4-.4s-.1-1 .4-1.3l5-3c.3-.2.7-.2 1 0l5 3c.5.3.6.9.3 1.4-.2.4-.8.6-1.3.3l-4.5-2.7-4.5 2.7z' fill='%23ff5630'/%3E %3Cpath d='M3.47876 12.2c-.5.3-1.1.2-1.4-.3s-.1-1.1.4-1.4l5-3c.3-.2.7-.2 1 0l5 3c.5.3.6.9.3 1.4-.3.5-.9.6-1.4.3l-4.4-2.7-4.5 2.7z' fill='%23ff7452'/%3E %3C/svg%3E");
}
.bug_tracker-priority__2UtcF.bug_tracker-medium__1_Ij5 {
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Warstwa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E %3Cstyle type='text/css'%3E .st0%7Bfill:%23FFAB00;%7D %3C/style%3E %3Ctitle%3Eicon/16px/medium-priority%3C/title%3E %3Cdesc%3ECreated with Sketch.%3C/desc%3E %3Cg id='icon_x2F_16px_x2F_medium-priority-'%3E %3Cg%3E %3Cpath class='st0' d='M3,4h10c0.6,0,1,0.4,1,1s-0.4,1-1,1H3C2.4,6,2,5.6,2,5S2.4,4,3,4z M3,10h10c0.6,0,1,0.4,1,1s-0.4,1-1,1H3 c-0.6,0-1-0.4-1-1S2.4,10,3,10z'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
}
